import * as React from 'react';
import { useMediaQuery, Theme, TableBody, TableCell } from '@mui/material';
import { Datagrid, DatagridProps, DatagridRow, Labeled, useTranslate, useListContext } from 'react-admin';

const SmallScreenDatagridBody: React.FC<DatagridProps> = (props) => {
  const { data = [], children, ...rest } = props;
  const { selectedIds, onToggleItem } = useListContext(); // リストコンテキストから選択管理機能を取得

  return (
    <TableBody>
      {data.map((record, rowIndex) => (
        <DatagridRow
          key={record.id ?? `row${rowIndex}`}
          id={record.id ?? `row${rowIndex}`}
          record={record}
          selected={selectedIds.includes(record.id)} // 選択されているかどうか
          onToggleItem={() => onToggleItem(record.id)} // 選択トグル機能
          {...(rest as any)}
        >
          <TableCell
            style={{
              display: 'block',
              padding: '16px',
              border: '1px solid #ccc',
              borderRadius: '8px',
              margin: '8px 0'
            }}
          >
            {React.Children.toArray(children).map((field, index) =>
              field && React.isValidElement<any>(field) ? (
                <div key={index} style={{ marginBottom: '8px' }}>
                  <Labeled key={field.props.source}>{field}</Labeled>
                </div>
              ) : null
            )}
          </TableCell>
        </DatagridRow>
      ))}
    </TableBody>
  );
};

const FlexibleListLayout: React.FC<DatagridProps> = (props) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  return (
    <Datagrid
      {...props}
      // isSmall のときだけヘッダーを非表示にする
      sx={isSmall ? { '& thead': { display: 'none' } } : undefined}
      body={isSmall ? <SmallScreenDatagridBody {...props} /> : undefined}
    />
  );
};

export default FlexibleListLayout;
