import { Box, useMediaQuery, Theme } from '@mui/material';

const SiteLogo = () => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const imgWidth = isSmall ? 208 : 363;
  const imgHeight = isSmall ? 51 : 90;

  return (
    <Box display="flex" justifyContent="center">
      <Box
        component="img"
        src="/images/Successfee.svg"
        alt="Successfee Logo"
        sx={{ width: imgWidth, height: imgHeight }}
      />
    </Box>
  );
};

export default SiteLogo;
