import { REGISTER_ROLE } from '../constants/choices';

const REGISTER_TOKEN_KEY = 'register_token';
const REGISTER_FORM_KEY = 'register_form';
const REGISTER_INVITE_SRC_KEY = 'invite';
const REGISTER_INVITE_SITE_KEY = 'inviteSite';

const ROLE_KEY = 'role';

export function setRegisterToken(url: string, issueToken: string | null = null): string | null {
  let token: string | null;
  if (issueToken === null) {
    token = new URLSearchParams(url).get('t');
    if (!token) {
      return null;
    }
  } else {
    token = issueToken;
  }
  sessionStorage.setItem(REGISTER_TOKEN_KEY, token);
  return token;
}

export function getInviteCode(url: string): string | null {
  const inviteCode = new URLSearchParams(url).get('site');
  if (!inviteCode) {
    return null;
  }
  return inviteCode;
}

export function getPartnerInviteCode(url: string): string | null {
  const inviteCode = new URLSearchParams(url).get('p');
  if (!inviteCode) {
    return null;
  }
  return inviteCode;
}

export function getRegisterToken(): string | null {
  return sessionStorage.getItem(REGISTER_TOKEN_KEY);
}

export function clearRegisterToken(): void {
  sessionStorage.removeItem(REGISTER_TOKEN_KEY);
}

export function setRegisterFormData(formData: object): void {
  sessionStorage.setItem(REGISTER_FORM_KEY, JSON.stringify(formData));
}

export function getRegisterFormData(): object | null {
  const obj = sessionStorage.getItem(REGISTER_FORM_KEY);
  if (!obj) {
    return null;
  }
  try {
    return JSON.parse(obj);
  } catch (error) {
    return null;
  }
}

export function clearRegisterFormData(): void {
  sessionStorage.removeItem(REGISTER_FORM_KEY);
}

export function setInviteSrcData(src: number, site: string | null = null): void {
  sessionStorage.setItem(REGISTER_INVITE_SRC_KEY, String(src));
  if (site) {
    sessionStorage.setItem(REGISTER_INVITE_SITE_KEY, site);
  }
}

export function getInviteSrcData(): number | null {
  const src = sessionStorage.getItem(REGISTER_INVITE_SRC_KEY);
  if (!src) {
    return null;
  }
  try {
    return parseInt(src, 10);
  } catch (ex) {
    return null;
  }
}

export async function checkToken(props: [key: string]): Promise<boolean> {
  // トークンが不正であればエラー扱い
  return true;
}

export function setRoleFlag(url: string): void {
  const roleId = extractRoleFlag(url);
  sessionStorage.setItem(ROLE_KEY, roleId);
}

export function extractRoleFlag(url: string): string {
  const isStaff = url.includes('-staff');
  const roleId =
    REGISTER_ROLE.find((r) => {
      if (isStaff) {
        return url.includes(r.id) && r.id.includes('-staff');
      } else {
        return url.includes(r.id) && !r.id.includes('-staff');
      }
    })?.id ?? '';
  return roleId;
}

export function getRoleFlag(): string {
  const role = sessionStorage.getItem(ROLE_KEY);
  if (!role) {
    return '';
  }
  return role;
}
