import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';

interface LabeledEnumTextFieldProps {
  source: string;
  translationKeyPrefix: string;
  label: string; // label プロパティを必須に設定
  helperText?: string; // オプションとして helperText を追加
}

interface LabeledTextFieldProps {
  source: string;
  label: string; // label プロパティを必須に設定
  helperText?: string | React.ReactNode;
}

// LabeledEnumTextField コンポーネント
export const LabeledEnumTextField = ({
  source,
  translationKeyPrefix,
  label,
  helperText
}: LabeledEnumTextFieldProps) => {
  const record = useRecordContext();
  const translate = useTranslate();

  if (!record) return null;

  const statusKey = `${translationKeyPrefix}.${record[source]}`;
  const translatedText = translate(statusKey);

  return (
    <Box mb={2}>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        {label}
      </Typography>
      <Typography>{translatedText}</Typography>
      {helperText && (
        <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
          {helperText} {/* helperText が指定されている場合は表示 */}
        </Typography>
      )}
    </Box>
  );
};

LabeledEnumTextField.propTypes = {
  source: PropTypes.string.isRequired,
  translationKeyPrefix: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string // helperText をオプションとして設定
};

// LabeledTextField コンポーネント
export const LabeledTextField = ({ source, label, helperText }: LabeledTextFieldProps) => {
  const record = useRecordContext();

  if (!record) return null;

  const fieldValue = record[source];

  return (
    <Box mb={2}>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        {label} {/* ラベルを表示 */}
      </Typography>
      <Typography>{fieldValue}</Typography>
      {helperText && (
        <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
          {helperText} {/* helperText が指定されている場合は表示 */}
        </Typography>
      )}
    </Box>
  );
};

LabeledTextField.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string // helperText をオプションとして設定
};
