import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTranslate, useDataProvider } from 'react-admin';
import Divider from '@mui/material/Divider';
import styled from '@emotion/styled';
import { format } from 'date-fns';

interface PaymentData {
  date: string;
  reward: number;
  partnerReward: number;
  introducerReward: number;
  administrativeFee: number;
  tax: number;
  totalAmount: number;
}

interface SummaryData {
  totalSalesString: string;
  currentReward: number;
  currentPartnerReward: number;
  currentIntroducerReward: number;
}

const CustomDivider = styled(Divider)(() => ({
  borderColor: '#0000004d'
}));

const formatNumber = (num: number): string => {
  return Math.floor(num).toLocaleString(); // 小数点以下を切り捨て
};

const formatDate = (dateString: string): string => {
  return format(new Date(dateString), 'yyyy/MM/dd'); // 日付を YYYY/MM/DD 形式に変換
};

const AffiliateSummary: React.FC = () => {
  const translate = useTranslate();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const dataProvider = useDataProvider();
  const [summary, setSummary] = useState<SummaryData>({
    totalSalesString: '0', // 初期値を文字列としてセット
    currentReward: 0,
    currentPartnerReward: 0,
    currentIntroducerReward: 0
  });
  const [paymentData, setPaymentData] = useState<PaymentData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // 現在の日時を取得
  const now = new Date();
  const formattedDate = now.toLocaleDateString('ja-JP', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  const formattedTime = now.toLocaleTimeString('ja-JP', {
    hour: '2-digit',
    minute: '2-digit'
  });

  useEffect(() => {
    const fetchSummaryData = async () => {
      try {
        const data = await dataProvider.getData(`payments`);

        setSummary({
          totalSalesString: data.totalSalesString, // 文字列として保持
          currentReward: data.currentReward,
          currentPartnerReward: data.currentPartnerReward,
          currentIntroducerReward: data.currentIntroducerReward
        });

        setPaymentData(data.data); // 個別の支払いデータもセットする
      } catch (err) {
        setError('データの取得に失敗しました。');
      } finally {
        setLoading(false);
      }
    };

    fetchSummaryData();
  }, [dataProvider]);

  if (loading) {
    return <Typography variant="body1">読み込み中...</Typography>;
  }

  if (error) {
    return (
      <Typography variant="body1" color="error">
        {error}
      </Typography>
    );
  }

  // rewardが0でないデータをフィルタリング
  const nonZeroPayments = paymentData.filter((payment) => payment.totalAmount !== 0);

  return (
    <Stack width="100%" gap={0}>
      {/* 合計データのラベル */}
      <Stack width="100%">
        <Grid container width="100%">
          <Box display="flex" justifyContent="flex-start" width="100%">
            <Typography variant="body1" sx={{ textAlign: 'left' }} gap={0}>
              {translate('resources.analytics.fields.currentReward')}
              <br />
              {`${formattedDate} ${formattedTime}現在`}
            </Typography>
          </Box>
        </Grid>
      </Stack>

      {/* 合計データの値 */}
      <Stack width="100%" alignItems="stretch">
        <Grid container width="100%" alignItems="flex-start">
          <Box width="100%" display="flex" alignItems="flex-start">
            <Typography variant="h3" sx={{ textAlign: 'left', width: '100%', fontWeight: 'bold' }}>
              {formatNumber(summary.currentReward)}
              {translate('values.graphTargetUnits.salesAmount')}
            </Typography>
          </Box>
        </Grid>
      </Stack>

      <CustomDivider />

      {/* 個別データを表示（データがある場合のみ） */}
      {nonZeroPayments.length > 0 && (
        <>
          {/* 個別データのラベル */}
          <Stack width="100%">
            <Grid container width="100%">
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-start" width="100%">
                  <Typography variant="body1" sx={{ textAlign: 'left' }}>
                    {translate('resources.analytics.fields.expectedPaymentDate')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-start" width="100%">
                  <Typography variant="body1" sx={{ textAlign: 'left' }}>
                    {translate('resources.analytics.fields.paymentAmount')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Stack>

          {/* 個別データの表示 */}
          {nonZeroPayments.map((payment, index) => (
            <Stack key={index} width="100%">
              <Grid container width="100%">
                <Grid item xs={6}>
                  <Box width="100%">
                    <Typography variant={isSmallScreen ? 'body1' : 'h5'} sx={{ textAlign: 'left' }}>
                      {formatDate(payment.date)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box width="100%">
                    <Typography variant={isSmallScreen ? 'body1' : 'h5'} sx={{ textAlign: 'left' }}>
                      {formatNumber(payment.totalAmount)}
                      {translate('values.graphTargetUnits.salesAmount')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          ))}
          <CustomDivider />
        </>
      )}
    </Stack>
  );
};

export default AffiliateSummary;
