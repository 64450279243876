import React, { ReactNode } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface LinkButtonProps extends ButtonProps {
  href: string;
  children: ReactNode;
}

const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const navigate = useNavigate();
  const linkPadding = `-6px`;

  return (
    <Button
      {...props}
      href={undefined}
      sx={{ mt: linkPadding }}
      variant="text"
      color="primary"
      onClick={() => navigate(props.href)}
    >
      {props.children}
    </Button>
  );
};

export default LinkButton;
