import styled from '@emotion/styled';
import Footer from './Footer';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const LayoutContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh'
});

const ContentContainer = styled(Box)({
  flex: '1 0 auto'
});

const RegisterLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { pathname } = useLocation();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const sxMargin = isSmall
    ? {
        mt: 5,
        mb: 5,
        ml: 2,
        mr: 2
      }
    : {
        mt: 10,
        mb: 10,
        ml: 3,
        mr: 3
      };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <LayoutContainer
      sx={{
        '& .MuiContainer-root': {
          padding: 0
        }
      }}
    >
      <ContentContainer sx={sxMargin}>{children}</ContentContainer>
      <Footer />
    </LayoutContainer>
  );
};

export default RegisterLayout;
