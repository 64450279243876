import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Container,
  Box,
  Paper,
  Stack,
  useMediaQuery
} from '@mui/material';
import { contractText } from './PartnerAgreementContent';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import RegisterHeaderProgress from '../common_modules/components/RegisterHeaderProgress';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import RegisterLayout from '../common_modules/components/RegisterLayout';
import RegisterSubmitButton from '../common_modules/components/RegisterSubmitButton';
import WrappedCheckBoxFormControlLabel from '../common_modules/components/WrappedCheckBoxFormControlLabel';
import LinkButton from '../common_modules/components/LinkButton';

const Contract: React.FC = () => {
  const [isAgreed, setIsAgreed] = React.useState(false);
  const navigate = useNavigate();
  const translate = useTranslate();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const registerToken = sessionStorage.getItem('register_token'); // セッションストレージからトークンを取得
    if (!registerToken) {
      // トークンが存在しない場合は token-error ページにリダイレクト
      navigate('/register/partner/token-error', { replace: true });
    }
  }, [navigate]);

  const handleAgreeChange = () => {
    setIsAgreed(!isAgreed);
  };

  const handleNext = () => {
    navigate('/register/partner/form');
  };

  const gap = isSmallScreen ? 3 : 5;
  return (
    <RegisterLayout>
      <Container>
        <Stack direction={'column'} spacing={gap}>
          <Helmet>
            <title>{translate('resources.registers.name.Contract')}</title>
          </Helmet>
          <RegisterHeaderProgress
            title={translate('resources.registers.name.Contract')}
            icon={<DescriptionOutlinedIcon />}
            step={2}
            totalSteps={7}
          />
          <Paper elevation={3} style={{ backgroundColor: '#F5F5F5', padding: 24, height: 540, overflow: 'auto' }}>
            <Typography variant="body1" paragraph dangerouslySetInnerHTML={{ __html: contractText }} />
          </Paper>

          <WrappedCheckBoxFormControlLabel
            control={<Checkbox checked={isAgreed} onChange={handleAgreeChange} />}
            label="契約内容に同意する"
          />

          <Stack alignItems="center" spacing={3}>
            <RegisterSubmitButton onClick={handleNext} disabled={!isAgreed}>
              次へ
            </RegisterSubmitButton>
            <LinkButton href="/register/partner/terms">{'< 利用規約に戻る'}</LinkButton>
          </Stack>
        </Stack>
      </Container>
    </RegisterLayout>
  );
};

export default Contract;
