import React, { useEffect, useState } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { SimpleForm, required, useTranslate, TextInput } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import useErrorHandler from '../hooks/useErrorHandler';
import dataProvider from '../../dataProvider';
import { getRegisterFormData, getRegisterToken, getRoleFlag, setRegisterFormData } from './Common';
import { Helmet } from 'react-helmet';
import { REGISTER_ROLE } from '../../common_modules/constants/choices';
import PasswordInputWithToggle from './PasswordInputWithToggle';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import RegisterHeaderProgress from './RegisterHeaderProgress';
import RegisterLayout from './RegisterLayout';
import RegisterSubmitButton from './RegisterSubmitButton';

const CustomToolbar = ({ loading }: { loading: boolean }) => (
  <Box display="flex" justifyContent="center" mt={5}>
    <RegisterSubmitButton type="submit" disabled={loading}>
      登録
    </RegisterSubmitButton>
  </Box>
);

const CreateStaff: React.FC = () => {
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const translate = useTranslate();
  const [defaultValues, setDefaultValues] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const roleId = getRoleFlag();
  const roleName = REGISTER_ROLE.find((r) => r.id === roleId)?.name || '';
  const baseRoleName = roleId.split('-')[0];

  const handleSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      let sendingFormData: any;
      let apiEndpoint: string;

      sendingFormData = {
        token: getRegisterToken(),
        password: data.password,
        passwordConfirm: data.passwordConfirm
      };
      apiEndpoint = `/registers/${baseRoleName}s/create-staff`;

      const existingFormData = getRegisterFormData();
      if (existingFormData) {
        setRegisterFormData({
          ...existingFormData,
          partnerEmail: data.email,
          userPassword: data.password,
          tel: data.tel,
          partnerName: 'partnerName' in existingFormData ? existingFormData.partnerName : data.name
        });
      }

      const response = await dataProvider.register(sendingFormData, apiEndpoint);
      navigate(`/register/${roleId}/complete`);
    } catch (error) {
      handleError();
      return (error as any).body.errors;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const formData = getRegisterFormData() as {
      partnerType?: number;
      partnerName?: string;
      lastName?: string;
      firstName?: string;
      partnerEmail?: string;
      partnerTel?: string;
    };
    if (formData) {
      setDefaultValues({
        name: formData.partnerName || `${formData.lastName} ${formData.firstName}`,
        email: formData.partnerEmail,
        tel: formData.partnerTel,
        ...formData
      });
    }

    const role = getRoleFlag();
    setIsStaff(role.includes('staff'));
  }, []);

  return (
    <RegisterLayout>
      <Container>
        <Helmet>
          <title>{`${roleName} ${translate('resources.registers.name.CreateStaff')}`}</title>
        </Helmet>
        <Box display="flex" justifyContent="center">
          <Box
            sx={{
              width: '100%',
              maxWidth: '600px'
            }}
          >
            <Stack spacing={1}>
              <Typography variant="h4">{translate('resources.registers.name.CreateStaff')}</Typography>

              <SimpleForm
                defaultValues={defaultValues}
                toolbar={<CustomToolbar loading={isLoading} />}
                sx={{ p: 0, '& .MuiTextField-root': { width: '100%' } }}
                onSubmit={handleSubmit}
                gap={3}
              >
                {!isStaff && (
                  <>
                    <TextInput
                      source="name"
                      label={translate('resources.registers.fields.userName')}
                      validate={required()}
                      helperText=""
                      sx={{ marginBottom: 0 }}
                    />
                    <TextInput
                      source="email"
                      label={translate('resources.registers.fields.userEmail')}
                      validate={required()}
                      helperText=""
                      sx={{ marginBottom: 0 }}
                    />
                  </>
                )}
                <PasswordInputWithToggle
                  source="password"
                  label={translate('resources.registers.fields.userPassword')}
                  validate={required()}
                  helperText="パスワードは8文字以上で、数字、記号、大文字、小文字をそれぞれ1つ以上含める必要があります。"
                  FormHelperTextProps={{ sx: { whiteSpace: 'normal' } }}
                />
                <PasswordInputWithToggle
                  source="passwordConfirm"
                  label={translate('resources.registers.fields.userPasswordConfirm')}
                  validate={required()}
                  FormHelperTextProps={{ sx: { whiteSpace: 'normal' } }}
                />
              </SimpleForm>
            </Stack>
          </Box>
        </Box>
      </Container>
    </RegisterLayout>
  );
};

export default CreateStaff;
