import { NumberField, NumberFieldProps } from 'react-admin';
import { Box } from '@mui/material';

const PercentNumberField = (props: NumberFieldProps) => {
  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      <NumberField {...props} />
      <span style={{ fontSize: '14px' }}>%</span>
    </Box>
  );
};

export default PercentNumberField;
