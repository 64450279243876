import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

const CustomButton = styled(Button)(({ theme }) => ({
  width: '240px',
  height: '56px',
  borderRadius: '4px'
}));

const RegisterSubmitButton: React.FC<ButtonProps> = (props) => {
  return (
    <CustomButton variant="contained" color="primary" {...props}>
      {props.children}
    </CustomButton>
  );
};

export default RegisterSubmitButton;
