import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  textAlign: 'center',
  width: '100%'
}));

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Typography variant="body1">Copyright © Successfee, Inc. All Rights Reserved.</Typography>
    </FooterContainer>
  );
};

export default Footer;
