import React, { useEffect, useRef, useState } from 'react';
import { useTranslate, useLogin, useNotify, useLogout } from 'react-admin';
import { Container, Typography, Box, Stack } from '@mui/material';
import { getRegisterFormData } from './Common';
import { Helmet } from 'react-helmet';
import AuthHelper from '../utils/AuthHelper';
import { useNavigate } from 'react-router-dom';
import RegisterLayout from './RegisterLayout';
import RegisterSubmitButton from './RegisterSubmitButton';

interface RegisterFormData {
  partnerEmail?: string;
  userPassword?: string;
}

const Completion: React.FC = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const login = useLogin();
  const logout = useLogout();
  const notify = useNotify();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const formData = getRegisterFormData() as RegisterFormData;
    if (formData) {
      const { partnerEmail, userPassword } = formData;
      if (partnerEmail && userPassword) {
        timeoutRef.current = setTimeout(async () => {
          try {
            await logout();
            await login({ username: partnerEmail, password: userPassword });
            navigate(`/`, { replace: true });
            window.location.reload();
          } catch (error) {
            notify(AuthHelper.getErrorKeyCode(error), { type: 'error' });
          }
        }, 10000); // 10秒後に自動ログイン
      }
    }

    // クリーンアップ関数でタイムアウトをクリア
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [login, logout, notify]);

  const handleLoginRedirect = async () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const formData = getRegisterFormData() as RegisterFormData;
    if (formData) {
      const { partnerEmail, userPassword } = formData;
      if (partnerEmail && userPassword) {
        try {
          await logout();
          await login({ username: partnerEmail, password: userPassword });
          navigate(`/`, { replace: true });
          window.location.reload();
        } catch (error) {
          notify(AuthHelper.getErrorKeyCode(error), { type: 'error' });
        }
      }
    }
  };

  return (
    <RegisterLayout>
      <Container>
        <Helmet>
          <title>{translate('resources.registers.name.Completion')}</title>
        </Helmet>
        <Stack spacing={5}>
          <Typography variant="h4">{translate('resources.registers.name.Completion')}</Typography>

          <Typography>
            ご登録ありがとうございます。Successfeeをご利用可能になりました。
            <br />
            <br />
            10秒後に自動で管理ページにログインします。
            <br />
            ページが切り替わらない場合は、以下ログインボタンを押してください。
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <RegisterSubmitButton onClick={handleLoginRedirect}>ログイン</RegisterSubmitButton>
          </Box>
        </Stack>
      </Container>
    </RegisterLayout>
  );
};

export default Completion;
