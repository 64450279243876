import React, { useEffect } from 'react';
import { UserMenu, useGetIdentity, Logout } from 'react-admin';
import { MenuItem, Avatar, Typography, Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const CustomUserBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'normal'
}));

const CustomUserIcon = () => {
  const { identity, isLoading } = useGetIdentity();
  const userName = !isLoading && identity?.fullName ? identity.fullName : 'DefaultUserName';
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const userButton = document.querySelector('.RaUserMenu-userButton');
    if (userButton) {
      Array.from(userButton.childNodes).forEach((node) => {
        if (node.nodeType === 3) {
          node.textContent = '';
        }
      });
    }
  }, []);

  return (
    <CustomUserBox>
      <Avatar sx={{ height: 30, width: 30, marginRight: 1 }}>
        <AccountCircleIcon />
      </Avatar>
      {!isSmallScreen && (
        <Typography
          variant="body2"
          noWrap
          sx={{
            textOverflow: 'ellipsis',
            maxWidth: '250px'
          }}
        >
          {userName}
        </Typography>
      )}
    </CustomUserBox>
  );
};

// カスタムユーザーメニュー
const CustomUserMenu: React.FC = (props) => {
  return (
    <UserMenu {...props} icon={<CustomUserIcon />}>
      <MenuItem>
        <Logout />
      </MenuItem>
    </UserMenu>
  );
};

export default CustomUserMenu;
