import React from 'react';
import { RadioButtonGroupInput, RadioButtonGroupInputProps, useInput } from 'react-admin';

interface NumericRadioButtonGroupInputProps extends Omit<RadioButtonGroupInputProps, 'source'> {
  source: string;
}

const NumericRadioButtonGroupInput: React.FC<NumericRadioButtonGroupInputProps> = (props) => {
  const { field } = useInput(props);
  const handleOnChange = (...event: any[]) => {
    field.onChange(parseInt(event[1], 10));
  };
  return (
    <RadioButtonGroupInput
      {...props}
      sx={{
        '& .MuiFormControlLabel-label': { fontSize: '14px' }
      }}
      onChange={handleOnChange}
    />
  );
};

export default NumericRadioButtonGroupInput;
