import { FormControlLabel, styled } from '@mui/material';

/**
 * チェックボックスのマージンを調整したFormControlLabel
 */
const WrappedCheckBoxFormControlLabel = styled(FormControlLabel)(() => ({
  marginTop: '-9px',
  marginBottom: '-9px'
}));

export default WrappedCheckBoxFormControlLabel;
