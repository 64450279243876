import { Toolbar, SaveButton } from 'react-admin';

interface SaveOnlyToolbarProps {
  disabled?: boolean;
}

const SaveOnlyToolbar = ({ disabled }: SaveOnlyToolbarProps) => (
  <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <SaveButton disabled={disabled} />
  </Toolbar>
);

export default SaveOnlyToolbar;
