import {
  Loyalty as AffiliateIcon,
  CurrencyYen as RewardsIcon,
  Settings as SettingsIcon,
  Group as UserIcon
} from '@mui/icons-material';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Admin, CustomRoutes, Layout, Resource } from 'react-admin';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Route } from 'react-router-dom';
import CustomMenu from './Menu';
import Dashboard from './resources/Dashboard';
import authProvider from './authProvider';
import Analytics from './common_modules/components/Analytics';
import CreateStaff from './common_modules/components/CreateStaff';
import CustomAppBar from './common_modules/components/CustomAppBar';
import CustomLoginPage from './common_modules/components/login/CustomLoginPage';
import ForgotPasswordPage from './common_modules/components/login/ForgotPasswordPage';
import ResetPasswordSuccessPage from './common_modules/components/login/ResetPasswordSuccessPage';
import SMSAuthentication from './common_modules/components/SMSAuthentication';
import AuthHelper from './common_modules/utils/AuthHelper';
import dataProvider from './dataProvider';
import { japaneseMessages } from './i18n/ja';
import Completion from './common_modules/components/Completion';
import Confirmation from './register/Confirmation';
import Form from './register/Form';
import Register from './common_modules/components/Register';
import RegisterError from './common_modules/components/RegisterError';
import RegisterTokenError from './common_modules/components/RegisterTokenError';
import StartRegistration from './register/StartRegistration';
import Terms from './register/Terms';
import Contract from './register/Contract';
import UploadId from './register/UploadId';
import { StaffCreate, StaffEdit, StaffList, StaffShow } from './resources/Accounts';
import { AffiliateCreate, AffiliateShow, AffiliatesList } from './resources/Affiliates';
import { RewardsList, RewardsShow } from './resources/Rewards';
import { SettingsEdit } from './resources/Settings';
import theme from './theme';
import Verification from './register/Verification';
import FinishRegistration from './register/FinishRegistration';
import { InvitationCreate, InvitationList } from './resources/Invitations';
import SiteLogo from './common_modules/components/SiteLogo';
import Step from './site/Step';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { jaJP } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { ChangedIdListProvider } from './common_modules/hooks/ChangedIdListCount';

AuthHelper.init(
  import.meta.env.VITE_COGNITO_ENDPOINT,
  import.meta.env.VITE_PARTNER_USER_POOL_ID,
  import.meta.env.VITE_PARTNER_USER_POOL_CLIENT_ID
);

export const i18nProvider = polyglotI18nProvider(() => japaneseMessages, 'ja');
const reCaptchaKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

const layout = (props: any) => <Layout {...props} appBar={CustomAppBar} menu={CustomMenu} />;
dayjs.locale('ja');
const App = () => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="ja"
      localeText={jaJP.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <ChangedIdListProvider>
        <Admin
          layout={layout}
          dashboard={Dashboard}
          loginPage={() => <CustomLoginPage subtitle="" backgroundColor="#5B6955" buttonColor="#5B6955" />}
          i18nProvider={i18nProvider}
          dataProvider={dataProvider}
          authProvider={authProvider}
          theme={theme}
        >
          <CustomRoutes noLayout>
            <Route
              path="/reset-password"
              element={<ForgotPasswordPage backgroundColor="#EDECED" buttonColor="#9FB694" />}
            />
            <Route
              path="/reset-complete"
              element={<ResetPasswordSuccessPage backgroundColor="#EDECED" buttonColor="#9FB694" />}
            />
          </CustomRoutes>

          <CustomRoutes>
            <Route path="/analytics" element={<Analytics selectedResultType={4} />} />
          </CustomRoutes>

          <CustomRoutes noLayout>
            <Route path="/register/partner" element={<Register />} />
            <Route path="/register/partner-staff" element={<Register />} />
            <Route path="/register/partner/start-register" element={<StartRegistration />} />
            <Route path="/register/partner/terms" element={<Terms />} />
            <Route path="/register/partner/contract" element={<Contract />} />
            <Route path="/register/partner/form" element={<Form />} />
            <Route
              path="/register/partner/confirm"
              element={
                <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
                  <Confirmation />
                </GoogleReCaptchaProvider>
              }
            />
            {/* <Route path="/register/partner/upload-id" element={<UploadId />} /> */}
            <Route path="/register/partner/finish" element={<FinishRegistration />} />

            <Route path="/register/partner/create-staff" element={<CreateStaff />} />
            <Route path="/register/partner/smsauth" element={<SMSAuthentication />} />
            <Route path="/register/partner/complete" element={<Completion />} />
            <Route path="/register/partner/verification" element={<Verification />} />

            <Route path="/register/partner/error" element={<RegisterError />} />
            <Route path="/register/partner/token-error" element={<RegisterTokenError />} />

            <Route path="/register/partner-staff/create-staff" element={<CreateStaff />} />
            <Route path="/register/partner-staff/smsauth" element={<SMSAuthentication />} />
            <Route path="/register/partner-staff/complete" element={<Completion />} />
            <Route path="/register/partner-staff/error" element={<RegisterError />} />

            <Route path="/link" element={<Step />} />
          </CustomRoutes>

          <Resource
            name="accounts"
            options={{ label: 'スタッフ管理' }}
            list={StaffList}
            show={StaffShow}
            edit={StaffEdit}
            create={StaffCreate}
            icon={UserIcon}
          />
          <Resource
            name="rewards"
            options={{ label: '報酬管理' }}
            list={RewardsList}
            show={RewardsShow}
            icon={RewardsIcon}
          />
          <Resource
            name="affiliates"
            options={{ label: 'PR用ツール' }}
            list={AffiliatesList}
            show={AffiliateShow}
            create={AffiliateCreate}
            icon={AffiliateIcon}
          />
          <Resource name="invitations" list={InvitationList} create={InvitationCreate} />

          <Resource name="settings" options={{ label: '設定' }} edit={SettingsEdit} icon={SettingsIcon} />
        </Admin>
      </ChangedIdListProvider>
    </LocalizationProvider>
  );
};

export default App;
