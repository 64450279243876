import { Box, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { FieldProps, useRecordContext } from 'react-admin';

interface CustomUrlFieldProps extends FieldProps {
  source: string;
  label?: string;
}

const CustomUrlField: React.FC<CustomUrlFieldProps> = ({ source, label }) => {
  const record = useRecordContext();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (window.confirm('このリンクを新しいタブで開きますか？')) {
      window.open(record[source], '_blank', 'noopener,noreferrer');
    }
  };

  return record ? (
    <Box>
      {label && (
        <Typography variant="caption" color="textSecondary" gutterBottom>
          {label}
        </Typography>
      )}
      <Box display="flex" alignItems="center">
        <Link
          href={record[source]}
          onClick={handleClick}
          style={{ marginRight: '8px', wordBreak: 'break-all', fontSize: '14px' }}
        >
          {record[source]}
        </Link>
      </Box>
    </Box>
  ) : null;
};

CustomUrlField.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string
};

export default CustomUrlField;
