import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Box } from '@mui/material';
import {
  Create,
  CreateButton,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  List,
  required,
  SaveButton,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useNotify,
  useRedirect,
  useTranslate,
  useRecordContext
} from 'react-admin';
import EnumTextField from '../common_modules/components/EnumTextField';
import NumericRadioButtonGroupInput from '../common_modules/components/NumericRadioButtonGroupInput';
import { ACCOUNT_STATUS, AUTHORITY_CHOICES } from '../common_modules/constants/choices';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import { defaultExporter } from '../common_modules/utils/exporter';
import FlexibleListLayout from '../common_modules/components/FlexibleListLayout';
import FormValidation from '../common_modules/components/FormValidation';

// インターフェース定義
interface Staff {
  name: string;
  email: string;
  authority: string;
}

const ACCOUNT_STATUS_EDIT = ACCOUNT_STATUS.filter((status) => status.id !== 2);

// スタッフのリストアクション用カスタムボタン
const CustomCreateButton = () => <CreateButton label="スタッフ追加" icon={<PersonAddAlt1Icon />} />;

const ListActions = () => (
  <TopToolbar>
    <CustomCreateButton />
  </TopToolbar>
);

// カスタムの作成用ツールバー
const CustomCreateToolbar = ({ isFormValid }: { isFormValid: boolean }) => (
  <Toolbar sx={{ justifyContent: 'space-between', width: '100%', mt: 2 }}>
    <SaveButton label="登録" icon={<MailOutlineIcon />} disabled={!isFormValid} />
  </Toolbar>
);

// カスタム削除ボタン
const CustomDeleteButton = () => {
  const record = useRecordContext();
  return (
    <Box sx={{ marginLeft: '16px' }}>
      <DeleteButton confirmTitle={`${record?.name} を削除`} confirmContent="本当に削除しますか？" />
    </Box>
  );
};

// 保存ボタンと削除ボタンを含むカスタムツールバー
const CustomEditToolbar = ({ isFormValid }: { isFormValid: boolean }) => (
  <Toolbar sx={{ justifyContent: 'space-between', width: '100%', mt: 2 }}>
    <SaveButton disabled={!isFormValid} />
    <CustomDeleteButton />
  </Toolbar>
);

// スタッフリストの表示
export const StaffList = () => {
  const translate = useTranslate();
  return (
    <List exporter={defaultExporter} actions={<ListActions />}>
      <FlexibleListLayout rowClick="show" bulkActionButtons={false}>
        <TextField source="name" label={translate('resources.accounts.fields.name')} />
        <TextField source="email" label={translate('resources.accounts.fields.email')} />
        <EnumTextField
          source="authority"
          translationKeyPrefix="values.authority"
          label={translate('resources.accounts.fields.authority')}
        />
        <EnumTextField source="accountInvalidFlag" translationKeyPrefix="values.accountInvalidFlag" />
        <DateField showTime={true} source="lastLoginDate" />
        <EditButton />
      </FlexibleListLayout>
    </List>
  );
};

interface ConditionalFieldProps {
  source: string;
  choices: Array<{ id: number; name: string }>;
  validate?: any;
}
const ConditionalNumericRadioButtonGroupInput = ({ source, choices, validate }: ConditionalFieldProps) => {
  const record = useRecordContext();
  return (
    <NumericRadioButtonGroupInput
      source={source}
      choices={choices}
      disabled={record?.accountInvalidFlag === 2}
      validate={validate}
    />
  );
};

// StaffEdit コンポーネント
export const StaffEdit = () => {
  const handleError = useErrorHandler();

  // 必須フィールドの定義
  const requiredFields = ['name', 'authority', 'accountInvalidFlag'];
  return (
    <Edit resource="accounts" mutationMode="pessimistic" mutationOptions={{ onError: handleError }}>
      <SimpleForm
        toolbar={
          <FormValidation requiredFields={requiredFields}>
            {(isFormValid) => <CustomEditToolbar isFormValid={isFormValid} />}
          </FormValidation>
        }
        sx={{ maxWidth: '600px' }}
      >
        <TextInput source="name" fullWidth validate={required()} />
        <SimpleShowLayout sx={{ paddingLeft: '0' }}>
          <TextField source="email" />
        </SimpleShowLayout>
        <NumericRadioButtonGroupInput source="authority" choices={AUTHORITY_CHOICES} validate={required()} />
        <ConditionalNumericRadioButtonGroupInput
          source="accountInvalidFlag"
          choices={ACCOUNT_STATUS_EDIT}
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};

// StaffCreate コンポーネント
export const StaffCreate = () => {
  const handleError = useErrorHandler();
  const notify = useNotify();
  const redirect = useRedirect();

  // 必須フィールドの定義
  const requiredFields = ['name', 'email', 'authority'];

  return (
    <Create
      title="スタッフ追加"
      mutationOptions={{
        onSuccess: (data: Staff) => {
          notify(`${data.name}を登録しました`, { type: 'info' });
          redirect('list', 'accounts');
        },
        onError: handleError
      }}
    >
      <SimpleForm
        toolbar={
          <FormValidation requiredFields={requiredFields}>
            {(isFormValid) => <CustomCreateToolbar isFormValid={isFormValid} />}
          </FormValidation>
        }
        sx={{ maxWidth: '600px' }}
      >
        <TextInput source="name" fullWidth validate={required()} />
        <TextInput source="email" fullWidth validate={required()} />
        <NumericRadioButtonGroupInput source="authority" choices={AUTHORITY_CHOICES} validate={required()} />
      </SimpleForm>
    </Create>
  );
};

// StaffShow コンポーネント
export const StaffShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="email" />
      <EnumTextField source="authority" translationKeyPrefix="values.authority" />
      <EnumTextField source="accountInvalidFlag" translationKeyPrefix="values.accountInvalidFlag" />
      <DateField showTime={true} source="lastLoginDate" />
    </SimpleShowLayout>
  </Show>
);
